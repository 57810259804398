<template>
    <div>
        <Header year="2025" :withLinks="false"></Header>
        <section v-if="registered" class="box my-1 px-3 px-0-desktop">
            <div class="container">
                <div class="columns">
                    <div class="column is-half">
                        <h1 class="is-size-3">Inscripció 2025</h1>
                        <p>
                            <i
                                class="is-size-1 has-text-primary fas fa-check"
                            ></i>
                            Has quedat correctament apuntat a la competició de
                            H&F més ben parida de la història!
                        </p>

                        <a class="button mt-5 w-100 is-primary" href="https://hikeandfly.puntxat.cat"
                            >Començar</a
                        >
                    </div>
                </div>
            </div>
        </section>

        <section v-else class="box my-1 px-3 px-0-desktop">
            <div class="container">
                <div class="columns">
                    <div class="column is-half">
                        <h1 class="is-size-3">Inscripció 2025</h1>
                        <form v-on:submit="submit">
                            <div class="field">
                                <label class="label">Nom</label>
                                <div class="control">
                                    <input
                                        required
                                        class="input"
                                        v-model="form.name"
                                        type="text"
                                        placeholder="Nom"
                                    />
                                </div>
                            </div>
                            <div class="field">
                                <label class="label">Cognoms</label>
                                <div class="control">
                                    <input
                                        required
                                        class="input"
                                        v-model="form.lastname"
                                        type="text"
                                        placeholder="Nom"
                                    />
                                </div>
                            </div>
                            <div class="columns mb-0">
                                <div class="column is-half">
                                    <div class="field">
                                        <label class="label">DNI</label>
                                        <div class="control">
                                            <input
                                                required
                                                class="input"
                                                v-bind:class="{
                                                    'is-danger': errors.dni?.length,
                                                }"
                                                v-model="form.dni"
                                                type="text"
                                                placeholder="DNI"
                                            />
                                        </div>
                                        <p
                                            v-if="errors.dni"
                                            class="help is-danger"
                                        >
                                            {{ errors.dni[0] }}
                                        </p>
                                    </div>
                                </div>
                                <div class="column is-half">
                                    <div class="field">
                                        <label class="label">Sexe</label>
                                        <div class="control">
                                            <label class="radio">
                                                <input
                                                    required
                                                    type="radio"
                                                    v-model="form.gender"
                                                    value="F"
                                                />
                                                Femení
                                            </label>
                                            <label class="radio">
                                                <input
                                                    required
                                                    type="radio"
                                                    v-model="form.gender"
                                                    value="M"
                                                />
                                                Masculí
                                            </label>
                                        </div>
                                        <p
                                            v-if="errors.gender"
                                            class="help is-danger"
                                        >
                                            {{ errors.gender[0] }}
                                        </p>
                                    </div>
                                </div>
                            </div>

                            <div class="field">
                                <label class="label">Correu electrònic</label>
                                <div class="control has-icons-left">
                                    <input
                                        required
                                        v-model="form.email"
                                        class="input"
                                        v-bind:class="{
                                            'is-danger': errors.email?.length,
                                        }"
                                        type="email"
                                        placeholder="Correu electrònic"
                                    />
                                    <span class="icon is-small is-left">
                                        <i class="fas fa-at"></i>
                                    </span>
                                </div>
                                <p v-if="errors.email" class="help is-danger">
                                    {{ errors.email[0] }}
                                </p>
                            </div>

                            <div class="columns mb-0">
                                <div class="column is-half">
                                    <div class="field">
                                        <label class="label"
                                            >Usuari volandoo</label
                                        >
                                        <div class="control has-icons-left">
                                            <input
                                                required
                                                v-model="form.volandoo"
                                                class="input"
                                                v-bind:class="{
                                                    'is-danger':
                                                        errors.volandoo?.length,
                                                }"
                                                type="text"
                                                placeholder="Usuari volandoo"
                                            />
                                            <span class="icon is-small is-left">
                                                <i class="fas fa-user"></i>
                                            </span>
                                        </div>
                                        <p
                                            v-if="errors.volandoo"
                                            class="help is-danger"
                                        >
                                            {{ errors.volandoo[0] }}
                                        </p>
                                    </div>
                                </div>
                                <div class="column is-half">
                                    <div class="field">
                                        <label class="label"
                                            >Equip (opcional)</label
                                        >
                                        <div class="control has-icons-left">
                                            <input
                                                v-model="form.team"
                                                class="input"
                                                v-bind:class="{
                                                    'is-danger':
                                                        errors.team?.length,
                                                }"
                                                type="text"
                                                placeholder="Nom equip"
                                            />
                                            <span class="icon is-small is-left">
                                                <i class="fas fa-users"></i>
                                            </span>
                                        </div>
                                        <p
                                            v-if="errors.team"
                                            class="help is-danger"
                                        >
                                            {{ errors.team[0] }}
                                        </p>
                                    </div>
                                </div>
                            </div>

                            <div class="columns">
                                <div class="column is-half">
                                    <div class="field">
                                        <label class="label">Marca parapent</label>
                                        <div class="control">
                                            <div class="select is-fullwidth">
                                                <select  v-model="form.glider">
                                                    <option>
                                                    </option>
                                                    <option v-for="brand in brands" :value="brand" :key="brand">
                                                        {{  brand  }}
                                                    </option>
                                                </select>
                                            </div>
                                        </div>
                                        <p
                                            v-if="errors.glider"
                                            class="help is-danger"
                                        >
                                            {{ errors.glider[0] }}
                                        </p>
                                    </div>
                                </div>
                            </div>

                            <progress
                                v-if="loading"
                                class="progress is-primary is-half"
                                max="100"
                            ></progress>
                            <button v-else class="button is-primary">
                                Guardar i pagar 36€
                            </button>
                        </form>
                    </div>
                </div>
            </div>
        </section>
    </div>
</template>

<script>
import Header from "../components/Header.vue";

export default {
    name: "Register",
    components: {
        Header,
    },
    data() {
        return {
            form: {},
            errors: {},
            registered: false,
            loading: false,
            brands: [],
        };
    },
    methods: {
        submit(e) {
            e.preventDefault();

            this.loading = true;

            fetch("/api/register", {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    Accept: "application/json",
                },
                body: JSON.stringify(this.form),
            })
                .then((res) => res.json())
                .then((data) => {
                    if (data.errors) this.errors = data.errors;
                    else if (data.payment) {
                        document.location.href = data.payment.url;
                    }
                    this.loading = false;
                });
        },
    },
    mounted() {
        fetch("/api/settings/brands")
            .then((res) => res.json())
            .then((data) => {
                this.brands = data;
            });
        this.$root.$emit("ready");
        this.registered = document.location.href.endsWith("benvinguda");
    },
};
</script>

<style lang="scss">
@import "bulma/sass/utilities/mixins.sass";

@include desktop {
    .px-0-desktop {
        padding-left: 0 !important;
        padding-right: 0 !important;
    }
}
</style>
