<template>
    <div class="has-text-centered" style="position: relative">
        <div
            class="table-container mb-0"
            style="overflow-y: auto; max-height: calc(100vh - 90px)"
        >
            <table
                class="table has-sticky-header has-sticky-column is-fullwidth is-striped"
                v-bind:class="{ 'is-showing-all-hikes': showAllHikes }"
            >
                <thead>
                    <tr>
                        <th
                            v-if="hasFilterByLeague"
                            class="separator"
                            colspan="2"
                            style="z-index: 10"
                        >
                            <LeagueSelectorDropdown
                                v-bind:leagues="leagues"
                                v-bind:leagueSelected="leagueSelected"
                                v-on:league-selected="setLeagueFilter"
                            />
                        </th>
                    </tr>
                </thead>
            </table>

            <footer class="footer">
                <small>Última actualització --</small>
                <div class="is-flex">
                    <img
                        src="../assets/sponsors/salewa.png"
                        class="image mx-auto my-3"
                        title="Tingladu Puntxat"
                    />
                    <img
                        src="../assets/puntxat.png"
                        class="image mx-auto my-3"
                        title="Tingladu Puntxat"
                    />
                    <img
                        src="../assets/sponsors/niviuk.png"
                        class="image mx-auto my-3"
                        title="Tingladu Puntxat"
                    />
                </div>
            </footer>
        </div>
    </div>
</template>

<script>
import LeagueSelectorDropdown from "./LeagueSelectorDropdown.vue";

export default {
    name: "Ranking",
    props: {
        showCombosScore: {
            type: Boolean,
            default: true,
        },
        showPositionVariations: {
            type: Boolean,
            default: true,
        },
        hasFilterByLeague: {
            type: Boolean,
            default: false,
        },
        leagues: {
            type: Array,
        },
        leagueSelected: {
            type: Object,
        },
    },
    components: {
        LeagueSelectorDropdown,
    },
    data() {
        return {
            data: null,
            pilotDetailsId: null,
            orderedBy: "score",
            filterByLeague: null,
            showAllHikes: false,
        };
    },
    computed: {
        filteredPilots() {
            if (!this.hasFilterByLeague || !this.filterByLeague)
                return this.data.pilots;

            return this.data.pilots.filter(
                (pilot) => pilot.gender == this.filterByLeague
            );
        },
        orderedPilots() {
            if (this.orderedBy == "integral")
                return this.orderedPilotsByIntegral();
            if (this.orderedBy == "hikes") return this.orderedPilotsByHikes();

            return this.orderedPilotsByScore();
        },
        rankingHasSomeIntegral() {
            for (var i = 0; i < this.filteredPilots.length; i++) {
                if (this.filteredPilots[i].integral_score > 0) return true;
            }

            return false;
        },
    },
    methods: {
        setShowAllHikes(value) {
            this.showAllHikes = value;
        },
        loadData(data) {
            this.data = data;
        },
        openPilotDetails(id) {
            this.pilotDetailsId = id;
        },
        closePilotDetails() {
            this.pilotDetailsId = null;
        },
        setOrdenationCriteria(criteria) {
            this.orderedBy = criteria;
        },
        setLeagueFilter(filter) {
            this.$emit("league-selected", filter);
        },
        /**
         * We will calculate position ordinal on runtime and
         * if point are equal, position is nos incremented
         * (to get the effect of a 5th position may be occupied for
         * 3 pilots)
         */
        orderedPilotsByScore() {
            var isDrawCallback = function (pilots, index) {
                return (
                    index > 0 &&
                    pilots[index - 1].score == pilots[index].score &&
                    pilots[index - 1].integral_time ==
                        pilots[index].integral_time
                );
            };

            return this.filteredPilots.recalculatePosition(isDrawCallback);
        },
        orderedPilotsByHikes() {
            var isDrawCallback = function (pilots, index) {
                return (
                    index > 0 &&
                    pilots[index - 1].hikes_score == pilots[index].hikes_score
                );
            };

            return [...this.filteredPilots]
                .sort((a, b) => {
                    if (a.hikes_score < b.hikes_score) return 1;
                    if (a.hikes_score > b.hikes_score) return -1;
                    return 0;
                })
                .recalculatePosition(isDrawCallback);
        },
        orderedPilotsByIntegral() {
            var isDrawCallback = function (pilots, index) {
                return (
                    index > 0 &&
                    pilots[index - 1].integral_score ==
                        pilots[index].integral_score &&
                    pilots[index - 1].integral_time ==
                        pilots[index].integral_time
                );
            };

            return [...this.filteredPilots]
                .sort((a, b) => {
                    if (
                        a.integral_score < b.integral_score ||
                        (a.integral_score == b.integral_score &&
                            a.integral_time > b.integral_time)
                    )
                        return 1;

                    if (
                        a.integral_score > b.integral_score ||
                        (a.integral_score == b.integral_score &&
                            a.integral_time < b.integral_time)
                    )
                        return -1;

                    return 0;
                })
                .recalculatePosition(isDrawCallback);
        },
    },
    mounted() {
        /*
        Array.prototype.recalculatePosition = function (isDrawCallback) {
            this.forEach((pilot, index) => {
                pilot.position = index + 1;
                if (isDrawCallback(this, index)) {
                    // we will repeat position number if there is a draw
                    pilot.position = this[index - 1].position;
                }
            });

            return this;
        };
        */
    },
};
</script>

<style lang="scss">
@import "bulma/sass/utilities/mixins.sass";

th {
    vertical-align: bottom !important;
}
.separator {
    border-right-width: 2px !important;
}
.container-overflow {
    height: calc(100vh - 90px);
    overflow: scroll;
}

.table.has-sticky-column thead th:nth-child(2),
.table.has-sticky-column tbody td:nth-child(2) {
    background-color: white;
    position: sticky;
    left: 75px;
    z-index: 1;
}

.table.has-sticky-column thead th:nth-child(2) {
    z-index: 6;
}

.table.has-sticky-column.is-striped
    tbody
    tr:not(.is-selected):nth-child(2n)
    td {
    background: #fafafa;
}

.w-75 {
    min-width: 75px !important;
}

.footer {
    position: sticky;
    left: 0;

    .image {
        height: 50px;
    }

    @include desktop {
        .image {
            height: 100px;
        }
    }
}

.is-showing-all-hikes {
    th.is-hidden-mobile {
        display: table-cell !important;
    }
}
</style>
