var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('Header',{attrs:{"year":"2025","withLinks":false}}),(_vm.registered)?_c('section',{staticClass:"box my-1 px-3 px-0-desktop"},[_vm._m(0)]):_c('section',{staticClass:"box my-1 px-3 px-0-desktop"},[_c('div',{staticClass:"container"},[_c('div',{staticClass:"columns"},[_c('div',{staticClass:"column is-half"},[_c('h1',{staticClass:"is-size-3"},[_vm._v("Inscripció 2025")]),_c('form',{on:{"submit":_vm.submit}},[_c('div',{staticClass:"field"},[_c('label',{staticClass:"label"},[_vm._v("Nom")]),_c('div',{staticClass:"control"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.form.name),expression:"form.name"}],staticClass:"input",attrs:{"required":"","type":"text","placeholder":"Nom"},domProps:{"value":(_vm.form.name)},on:{"input":function($event){if($event.target.composing)return;_vm.$set(_vm.form, "name", $event.target.value)}}})])]),_c('div',{staticClass:"field"},[_c('label',{staticClass:"label"},[_vm._v("Cognoms")]),_c('div',{staticClass:"control"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.form.lastname),expression:"form.lastname"}],staticClass:"input",attrs:{"required":"","type":"text","placeholder":"Nom"},domProps:{"value":(_vm.form.lastname)},on:{"input":function($event){if($event.target.composing)return;_vm.$set(_vm.form, "lastname", $event.target.value)}}})])]),_c('div',{staticClass:"columns mb-0"},[_c('div',{staticClass:"column is-half"},[_c('div',{staticClass:"field"},[_c('label',{staticClass:"label"},[_vm._v("DNI")]),_c('div',{staticClass:"control"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.form.dni),expression:"form.dni"}],staticClass:"input",class:{
                                                'is-danger': _vm.errors.dni?.length,
                                            },attrs:{"required":"","type":"text","placeholder":"DNI"},domProps:{"value":(_vm.form.dni)},on:{"input":function($event){if($event.target.composing)return;_vm.$set(_vm.form, "dni", $event.target.value)}}})]),(_vm.errors.dni)?_c('p',{staticClass:"help is-danger"},[_vm._v(" "+_vm._s(_vm.errors.dni[0])+" ")]):_vm._e()])]),_c('div',{staticClass:"column is-half"},[_c('div',{staticClass:"field"},[_c('label',{staticClass:"label"},[_vm._v("Sexe")]),_c('div',{staticClass:"control"},[_c('label',{staticClass:"radio"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.form.gender),expression:"form.gender"}],attrs:{"required":"","type":"radio","value":"F"},domProps:{"checked":_vm._q(_vm.form.gender,"F")},on:{"change":function($event){return _vm.$set(_vm.form, "gender", "F")}}}),_vm._v(" Femení ")]),_c('label',{staticClass:"radio"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.form.gender),expression:"form.gender"}],attrs:{"required":"","type":"radio","value":"M"},domProps:{"checked":_vm._q(_vm.form.gender,"M")},on:{"change":function($event){return _vm.$set(_vm.form, "gender", "M")}}}),_vm._v(" Masculí ")])]),(_vm.errors.gender)?_c('p',{staticClass:"help is-danger"},[_vm._v(" "+_vm._s(_vm.errors.gender[0])+" ")]):_vm._e()])])]),_c('div',{staticClass:"field"},[_c('label',{staticClass:"label"},[_vm._v("Correu electrònic")]),_c('div',{staticClass:"control has-icons-left"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.form.email),expression:"form.email"}],staticClass:"input",class:{
                                        'is-danger': _vm.errors.email?.length,
                                    },attrs:{"required":"","type":"email","placeholder":"Correu electrònic"},domProps:{"value":(_vm.form.email)},on:{"input":function($event){if($event.target.composing)return;_vm.$set(_vm.form, "email", $event.target.value)}}}),_vm._m(1)]),(_vm.errors.email)?_c('p',{staticClass:"help is-danger"},[_vm._v(" "+_vm._s(_vm.errors.email[0])+" ")]):_vm._e()]),_c('div',{staticClass:"columns mb-0"},[_c('div',{staticClass:"column is-half"},[_c('div',{staticClass:"field"},[_c('label',{staticClass:"label"},[_vm._v("Usuari volandoo")]),_c('div',{staticClass:"control has-icons-left"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.form.volandoo),expression:"form.volandoo"}],staticClass:"input",class:{
                                                'is-danger':
                                                    _vm.errors.volandoo?.length,
                                            },attrs:{"required":"","type":"text","placeholder":"Usuari volandoo"},domProps:{"value":(_vm.form.volandoo)},on:{"input":function($event){if($event.target.composing)return;_vm.$set(_vm.form, "volandoo", $event.target.value)}}}),_vm._m(2)]),(_vm.errors.volandoo)?_c('p',{staticClass:"help is-danger"},[_vm._v(" "+_vm._s(_vm.errors.volandoo[0])+" ")]):_vm._e()])]),_c('div',{staticClass:"column is-half"},[_c('div',{staticClass:"field"},[_c('label',{staticClass:"label"},[_vm._v("Equip (opcional)")]),_c('div',{staticClass:"control has-icons-left"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.form.team),expression:"form.team"}],staticClass:"input",class:{
                                                'is-danger':
                                                    _vm.errors.team?.length,
                                            },attrs:{"type":"text","placeholder":"Nom equip"},domProps:{"value":(_vm.form.team)},on:{"input":function($event){if($event.target.composing)return;_vm.$set(_vm.form, "team", $event.target.value)}}}),_vm._m(3)]),(_vm.errors.team)?_c('p',{staticClass:"help is-danger"},[_vm._v(" "+_vm._s(_vm.errors.team[0])+" ")]):_vm._e()])])]),_c('div',{staticClass:"columns"},[_c('div',{staticClass:"column is-half"},[_c('div',{staticClass:"field"},[_c('label',{staticClass:"label"},[_vm._v("Marca parapent")]),_c('div',{staticClass:"control"},[_c('div',{staticClass:"select is-fullwidth"},[_c('select',{directives:[{name:"model",rawName:"v-model",value:(_vm.form.glider),expression:"form.glider"}],on:{"change":function($event){var $$selectedVal = Array.prototype.filter.call($event.target.options,function(o){return o.selected}).map(function(o){var val = "_value" in o ? o._value : o.value;return val}); _vm.$set(_vm.form, "glider", $event.target.multiple ? $$selectedVal : $$selectedVal[0])}}},[_c('option'),_vm._l((_vm.brands),function(brand){return _c('option',{key:brand,domProps:{"value":brand}},[_vm._v(" "+_vm._s(brand)+" ")])})],2)])]),(_vm.errors.glider)?_c('p',{staticClass:"help is-danger"},[_vm._v(" "+_vm._s(_vm.errors.glider[0])+" ")]):_vm._e()])])]),(_vm.loading)?_c('progress',{staticClass:"progress is-primary is-half",attrs:{"max":"100"}}):_c('button',{staticClass:"button is-primary"},[_vm._v(" Guardar i pagar 36€ ")])])])])])])],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"container"},[_c('div',{staticClass:"columns"},[_c('div',{staticClass:"column is-half"},[_c('h1',{staticClass:"is-size-3"},[_vm._v("Inscripció 2025")]),_c('p',[_c('i',{staticClass:"is-size-1 has-text-primary fas fa-check"}),_vm._v(" Has quedat correctament apuntat a la competició de H&F més ben parida de la història! ")]),_c('a',{staticClass:"button mt-5 w-100 is-primary",attrs:{"href":"https://hikeandfly.puntxat.cat"}},[_vm._v("Començar")])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('span',{staticClass:"icon is-small is-left"},[_c('i',{staticClass:"fas fa-at"})])
},function (){var _vm=this,_c=_vm._self._c;return _c('span',{staticClass:"icon is-small is-left"},[_c('i',{staticClass:"fas fa-user"})])
},function (){var _vm=this,_c=_vm._self._c;return _c('span',{staticClass:"icon is-small is-left"},[_c('i',{staticClass:"fas fa-users"})])
}]

export { render, staticRenderFns }