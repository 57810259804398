<template>
    <div>
        <HeaderPreviousEditions title="Edició 2024">
            <a
                href="https://hikeandfly.puntxat.cat/admin/storage/hf_bergueda_normativa_2024.pdf"
                class="navbar-item"
                >Normativa</a
            >
        </HeaderPreviousEditions>
            
        <Ranking
            ref="ranking"
            v-bind:hasFilterByLeague="true"
            v-bind:leagues="$options.leagues"
            v-bind:leagueSelected="leagueSelected"
            v-on:league-selected="setLeagueSelected"
        ></Ranking>
    </div>
</template>

<script>
import HeaderPreviousEditions from "../components/HeaderPreviousEditions.vue";
import Ranking from "../components/Ranking.vue";
import results from "./ranking-2024.json";

export default {
    name: "Edition2024",
    RESULTS2024: results,
    components: {
        HeaderPreviousEditions,
        Ranking,
    },
    leagues: [
        {
            key: null,
            value: "Classificació general",
        },
        {
            key: "F",
            value: "Classificació femenina",
        },
    ],
    data() {
        return {
            leagueSelected: {
                key: null,
                value: "Classificació general",
            },
        };
    },
    methods: {
        setLeagueSelected(league) {            
            this.leagueSelected = league;
        },
    },
    mounted() {
        this.$refs.ranking.loadData(this.$options.RESULTS2024.data);
        this.$root.$emit("ready");
    },
};
</script>
