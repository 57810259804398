<template>
    <div>
        <Header :year="'2025'"></Header>
        <Ranking
            v-show="showPilotsRanking"
            ref="ranking"
            v-bind:hasFilterByLeague="true"
            v-bind:leagues="leagues"
            v-bind:leagueSelected="leagueSelected"
            v-on:league-selected="setLeagueSelected"
        ></Ranking>
        <RankingPerTeams
            v-show="showTeamsRanking"
            v-bind:hasFilterByLeague="true"
            v-bind:leagues="leagues"
            v-bind:leagueSelected="leagueSelected"
            v-on:league-selected="setLeagueSelected"
        ></RankingPerTeams>
    </div>
</template>

<script>
import Header from "../components/Header.vue";
import Ranking from "../components/Ranking.vue";
import RankingPerTeams from "../components/RankingPerTeams.vue";

export default {
    name: "Edition2025",
    components: {
        Header,
        Ranking,
        RankingPerTeams,
    },
    data() {
        return {
            leagues: [
                {
                    key: null,
                    value: "Classificació general",
                    callback: this.openPilotsRanking,
                },
                {
                    key: "F",
                    value: "Classificació femenina",
                    callback: this.openPilotsRanking,
                },
                {
                    key: "T",
                    value: "Classificació per equips",
                    callback: this.openTeamRanking,
                },
            ],
            showPilotsRanking: true,
            showTeamsRanking: false,
            leagueSelected: {
                key: null,
                value: "Classificació general",
            },
        };
    },
    methods: {
        openPilotsRanking() {
            this.showPilotsRanking = true;
            this.showTeamsRanking = false;
        },
        openTeamRanking() {
            this.showPilotsRanking = false;
            this.showTeamsRanking = true;
        },
        setLeagueSelected(league) {
            console.log("setLeagueSelected", league);
            this.leagueSelected = league;
        },
    },
    mounted() {
        this.$http.get("/api/ranking").then((response) => {
            this.$refs["ranking"].loadData(response.data.data);
            let vm = this;
            setTimeout(function () {
                vm.$root.$emit("ready");
            }, 50);
        });
    },
};
</script>
