import Vue from 'vue'
import VueRouter from 'vue-router'
import Edition2025 from '../pages/Edition2025.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Edition2025',
    component: Edition2025
  },
  {
    path: '/registre/(benvinguda)*',
    name: 'register',
    component: () => import(/* webpackChunkName: "register" */ '../pages/Register.vue')
  },
  {
    path: '/edicio-2022',
    name: 'Edition2022',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "edicio-2022" */ '../pages/Edition2022.vue')
  },
  {
    path: '/edicio-2023',
    name: 'Edition2023',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "edicio-2023" */ '../pages/Edition2023.vue')
  },
  {
    path: '/edicio-2024',
    name: 'Edition2024',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "edicio-2024" */ '../pages/Edition2024.vue')
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
